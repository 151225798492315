import * as React from 'react';
import { graphql } from 'gatsby';

import { PagesQuery } from '../../graphql-types';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import { RichText } from '../components/RichText';
import SEO from '../components/seo';

interface PageProps {
  data: PagesQuery;
}

export const query = graphql`
  query Pages($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      slug
      title
      body {
        json
      }
    }
  }
`;

export default ({ data }: PageProps) => {
  const p = data.contentfulPage;
  const bodyJson = p?.body?.json;
  const title = p?.title as string;

  return (
    <Layout>
      <SEO title={title} />

      <Section>
        <Container>
          <h1>{title}</h1>

          <RichText json={bodyJson} />
        </Container>
      </Section>
    </Layout>
  );
};
